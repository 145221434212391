import React from "react";
import Container from "../../../components/shared/Container";
import { DatoCmsTripDetail } from "../../types";

type Props = {
  section: DatoCmsTripDetail;
};

export default function Trip({ section }: Props) {
  return (
    <Container>
      <div className="p-4 flex border mb-3">
        <div className="w-2/3 md:text-lg">{section.from}</div>

        <div className="w-1/3">
          <div className="flex flex-col text-end md:flex-row-reverse md:justify-between">
            <div className="text-xl font-bold">{section.cost}</div>
            <div>{section.duration}</div>
          </div>
        </div>
      </div>
    </Container>
  );
}
