import React from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import Container from "../../../components/shared/Container";
import FAQItem from "./FAQ";
import {
  DatoCmsContentBase,
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
} from "../../types";

import { StructuredText } from "react-datocms";

type Props = {
  section: DatoCmsContentMediaBlock | DatoCmsListBlock | DatoCmsContentBase;
};

export default function FAQTemplate({ section }: Props): JSX.Element {
  const { faqs }: { faqs: { edges: { node: any }[] } } = useStaticQuery(
    graphql`
      query {
        faqs: allDatoCmsFrequentAskedQuestion {
          edges {
            node {
              id
              question
              answer
            }
          }
        }
      }
    `
  );

  const text = section?.stringContent ? (
    <p>{section?.stringContent}</p>
  ) : (
    <>
      {section?.content?.value ? (
        <StructuredText data={section.content} />
      ) : null}
    </>
  );

  return (
    <Container>
      <div className="flex flex-wrap items-start -mx-3">
        <div className="px-3 w-full md:w-3/12">
          <div>
            <h2 className="inline-block text-2xl font-bold md:text-4xl border-b-4 mb-2 border-primary">
              {section.title}
            </h2>
            <h3 className="text-xl md:text-2xl mb-4">{section.subTitle}</h3>
          </div>
          {text ? <div className="mt-2">{text}</div> : null}
        </div>
        <div className="px-3 w-full md:w-1/12"></div>
        <div className="px-3 w-full md:w-8/12">
          {faqs.edges.map(({ node }) => (
            <FAQItem key={node.id} faq={node} />
          ))}
        </div>
      </div>
    </Container>
  );
}
