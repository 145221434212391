import React from "react";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  size?: string;
};

export default function Container({ children, className = "", size = "max-w-screen-xl" }: Props) {
  return (
    <div className={`container ${size} mx-auto px-6 ${className}`}>
      {children}
    </div>
  );
}
