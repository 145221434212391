import axios from "axios";
import OrderService from "./OrderService";
import LegacyService from "./LegacyService";
import CommerceLayerAuthService from "./auth";

const CL_BASEURL = process.env.GATSBY_APP_CL_BASEURL + "/api";

export const ApiClient = axios.create({
  baseURL: CL_BASEURL,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
});

ApiClient.interceptors.request.use(
  async (config: any) => {
    const accessToken = await CommerceLayerAuthService.getAccessToken();
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.error(error);
    if (
      error.response.status === 401 &&
      error.response.data.errors &&
      error.response.data.errors[0].code === "INVALID_TOKEN"
    ) {
      const accessToken = await CommerceLayerAuthService.updateAccessToken();
      error.config.headers["Authorization"] = `Bearer ${accessToken}`;
      return ApiClient.request(error.config);
    }
    return Promise.reject(error);
  }
);

export const serviceLog = (serviceName: string, name: string) => {
  return `${serviceName}::${name}`;
};

const CommerceLayerApi = {
  LegacyService,
  OrderService,
};
export default CommerceLayerApi;
