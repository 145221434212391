import React from "react";

type Props = {
  value: number;
  setValue: (value: number) => void;
  max?: number;
};

export default function QuantitySelector({
  value,
  setValue,
  max,
}: Props) {
  return (
    <div className="inline-block border border-grey rounded overflow-hidden">
      <div className="flex items">
        <button
          className="w-4 h-7 bg-grey text-dark"
          onClick={() => {
            if (value > 1) {
              setValue(value - 1);
            }
          }}
        >
          -
        </button>
        <div
          className="w-6 h-7 flex items-center justify-center"
          // type="number"
          // value={value}
          // onChange={(e) => {
          //   const newValue = parseInt(e.target.value);
          //   if (newValue > 0 && (max ? newValue <= max : true)) {
          //     setValue(newValue);
          //   }
          // }}
        >
          {value}
        </div>
        <button
          className="w-4 h-7 bg-grey text-dark"
          onClick={() => {
            const newValue = value + 1;
            if (max ? newValue <= max : true) {
              setValue(newValue);
            }
          }}
        >
          +
        </button>
      </div>
    </div>
  );
}
