import _ from "lodash";
// @ts-ignore
import normalize from "json-api-normalize";

import {
  orderIncludes,
  addressAttributes,
  shipmentIncludes,
  shipmentAttributes,
  paymentSourceAttributesMap,
  orderDefaults,
  customerAddressAttributes,
} from "./attributes";
import {
  CommerceLayerOrder,
  CommerceLayerPaymentMethod,
  CommerceLayerPaymentSource,
} from "../OrderService/types";
import { ApiClient, serviceLog } from "..";
import { normalizedOrder } from "../utils";

const serviceName = "OrderService";

const createCustomerSubscription = (customerEmail: string) => {
  return ApiClient.post("/customer_subscriptions", {
    data: {
      type: "customer_subscriptions",
      attributes: {
        customer_email: customerEmail,
        reference: process.env.GATSBY_APP_SUBSCRIPTION_REF,
      },
    },
  })
    .then((response) => {
      return normalize(response.data).get(["id"]);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateCustomerSubscription = (
  customerEmail: string,
  customerSubscription: any
) => {
  return ApiClient.patch("/customer_subscriptions/" + customerSubscription.id, {
    data: {
      type: "customer_subscriptions",
      id: customerSubscription.id,
      attributes: {
        customer_email: customerEmail,
        reference: process.env.GATSBY_APP_SUBSCRIPTION_REF,
      },
    },
  })
    .then((response) => {
      return normalize(response.data).get(["id"]);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const deleteCustomerSubscription = (customerSubscription: any) => {
  return ApiClient.delete("/customer_subscriptions/" + customerSubscription.id)
    .then(() => {
      return Promise.resolve({});
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const handleCustomerSubscription = (
  customerEmail: string,
  customerSubscription: any
) => {
  if (customerSubscription.checked) {
    if (customerSubscription.id) {
      return updateCustomerSubscription(customerEmail, customerSubscription);
    } else {
      return createCustomerSubscription(customerEmail);
    }
  } else if (customerSubscription.id) {
    return deleteCustomerSubscription(customerSubscription);
  } else {
    return Promise.resolve({});
  }
};

const getOrder = (orderId: string) => {
  return ApiClient.get(
    "/orders/" + orderId + "?include=" + orderIncludes.join(",")
  )
    .then((response) => {
      let order = normalizedOrder(response.data, response);
      return normalizedOrder(order, response);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getOrder2 = (orderId: string) => {
  return ApiClient.get("/orders/" + orderId)
    .then((response) => {
      let order = normalizedOrder(response.data, response);
      return normalizedOrder(order, response);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getCustomerAddresses = () => {
  return ApiClient.get("/customer_addresses?include=address")
    .then((response) => {
      return normalize(response.data).get(customerAddressAttributes);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getCustomerPaymentSources = () => {
  return ApiClient.get(
    "/customer_payment_sources?include=payment_source"
  ).catch((error) => {
    return Promise.reject(error.response);
  });
};

const updateShipmentShippingMethod = (shipment: any, shippingMethod: any) => {
  return ApiClient.patch(
    "/shipments/" + shipment.id + "?include=" + shipmentIncludes.join(","),
    {
      data: {
        type: "shipments",
        id: shipment.id,
        relationships: {
          shipping_method: {
            data: {
              type: "shipping_methods",
              id: shippingMethod.id,
            },
          },
        },
      },
    }
  )
    .then((response) => {
      return normalize(response.data).get(shipmentAttributes);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateOrder = async (
  order: CommerceLayerOrder,
  attributes: Record<string, any>
) => {
  try {
    const { data } = await ApiClient.patch(
      "/orders/" + order.id + "?include=" + orderIncludes.join(","),
      {
        data: {
          type: "orders",
          id: order.id,
          attributes: attributes,
        },
      }
    );

    return normalizedOrder(order, data) || order;
  } catch (error) {
    console.error("updateOrder", order);
  }

  return order;
};

const createAddress = (attributes: any) => {
  return ApiClient.post(`/addresses`, {
    data: {
      type: "addresses",
      attributes: _.omit(attributes, ["id"]),
    },
  })
    .then((response) => {
      return normalize(response.data).get(addressAttributes);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateAddress = (attributes: any) => {
  return ApiClient.patch(`/addresses/${attributes.id}`, {
    data: {
      type: "addresses",
      id: attributes.id,
      attributes: _.omit(attributes, ["id"]),
    },
  })
    .then((response) => {
      return normalize(response.data).get(addressAttributes);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const saveBillingAddress = (order: CommerceLayerOrder) => {
  return order._save_billing_address_to_customer_address_book
    ? updateOrder(order, {
        _save_billing_address_to_customer_address_book:
          order._save_billing_address_to_customer_address_book,
      })
    : order;
};

const saveShippingAddress = (order: CommerceLayerOrder) => {
  return order._save_shipping_address_to_customer_address_book
    ? updateOrder(order, {
        _save_shipping_address_to_customer_address_book:
          order._save_shipping_address_to_customer_address_book,
      })
    : order;
};

const updateOrCreateBillingAddress = (order: CommerceLayerOrder) => {
  try {
    if (order._billing_address_clone_id) {
      return updateOrder(order, {
        _billing_address_clone_id: order._billing_address_clone_id,
      }).then((order) => {
        return order.billing_address;
      });
    } else {
      return order.billing_address?.id
        ? updateAddress(order.billing_address).then((address) => {
            saveBillingAddress(order);
            return address;
          })
        : createAddress(order.billing_address).then((address) => {
            saveBillingAddress(order);
            return address;
          });
    }
  } catch (error) {
    console.error("updateOrCreateBillingAddress", error);
    throw error;
  }
};
const updateBillingAddressFields = (
  order: CommerceLayerOrder,
  billingAddress: any
) => {
  return ApiClient.patch(
    "/orders/" + order.id + "?include=" + orderIncludes.join(","),
    {
      data: {
        type: "orders",
        id: order.id,
        attributes: {
          _shipping_address_same_as_billing: !order.ship_to_different_address,
        },
        relationships: {
          billing_address: {
            data: {
              type: "addresses",
              id: billingAddress.id,
            },
          },
        },
      },
    }
  ).then((response) => {
    return normalizedOrder(order, response.data);
  });
};

const updateShippingAddressFields = (
  order: CommerceLayerOrder,
  shippingAddress: any
) => {
  return ApiClient.patch(
    "/orders/" + order.id + "?include=" + orderIncludes.join(","),
    {
      data: {
        type: "orders",
        id: order.id,
        relationships: {
          shipping_address: {
            data: {
              type: "addresses",
              id: shippingAddress.id,
            },
          },
        },
      },
    }
  ).then((response) => {
    return normalizedOrder(order, response);
  });
};

const updateOrderAddresses = async (
  order: CommerceLayerOrder
): Promise<CommerceLayerOrder | null> => {
  try {
    const billingAddress = await updateOrCreateBillingAddress(order);
    const updatedOrder = await updateBillingAddressFields(
      order,
      billingAddress
    );
    return updatedOrder;
  } catch (error) {
    console.error("updateOrderAddresses", error);
  }

  return null;
};

const updateOrderPaymentMethod = async (
  order: CommerceLayerOrder,
  paymentMethod: CommerceLayerPaymentMethod
): Promise<CommerceLayerPaymentMethod> => {
  try {
    const { data } = await ApiClient.patch(
      "/orders/" + order.id + "?include=" + orderIncludes.join(","),
      {
        data: {
          type: "orders",
          id: order.id,
          relationships: {
            payment_method: {
              data: {
                type: "payment_methods",
                id: paymentMethod.id,
              },
            },
          },
        },
      }
    );

    const newOrder = normalizedOrder(data.data, data);

    if (newOrder?.payment_method) {
      return newOrder.payment_method;
    }
  } catch (error) {
    console.error("updateOrderPaymentMethod", error);
  }

  return Promise.reject("updateOrderPaymentMethod");
};

const createOrderPaymentSource = async (
  order: CommerceLayerOrder,
  paymentMethod: CommerceLayerPaymentMethod,
  paymentSourceAttributes: Record<string, any>
) => {
  try {
    const { data } = await ApiClient.post(
      `/${paymentMethod.payment_source_type}`,
      {
        data: {
          type: paymentMethod.payment_source_type,
          attributes: paymentSourceAttributes,
          relationships: {
            order: {
              data: {
                type: "orders",
                id: order.id,
              },
            },
          },
        },
      }
    );

    let attributes =
      paymentSourceAttributesMap[paymentMethod.payment_source_type];

    let normalizedPaymentSource = normalize(data).get(attributes);

    return normalizedPaymentSource as CommerceLayerPaymentSource;
  } catch (error) {
    console.error("createOrderPaymentSource", error);
  }

  return null;
};
const getOrderPaymentSource = async (
  paymentSource: CommerceLayerOrder["payment_source"],
  paymentMethod: CommerceLayerOrder["payment_method"]
): Promise<CommerceLayerOrder["payment_source"]> => {
  try {
    if (!paymentSource)
      return Promise.reject(new Error("No payment method found on order"));
    if (!paymentMethod)
      return Promise.reject(new Error("No payment method found on order"));

    const { data } = await ApiClient.get(
      `/${paymentMethod.payment_source_type}/${paymentSource.id}`
    );

    let attributes =
      paymentSourceAttributesMap[paymentMethod.payment_source_type];
    let normalizedPaymentSource = normalize(data).get(attributes);

    return normalizedPaymentSource;
  } catch (error) {
    console.error("getOrderPaymentSource", error);
  }

  return Promise.reject(new Error("getOrderPaymentSource error"));
};
const updateOrderPaymentSource = async (
  order: CommerceLayerOrder,
  paymentSourceAttributes: any
) => {
  if (!order.payment_method)
    return Promise.reject(new Error("No payment source found on order"));

  if (!order.payment_source)
    return Promise.reject(new Error("No payment source found on order"));

  const { data } = await ApiClient.patch(
    `/${order.payment_method.payment_source_type}/${order.payment_source.id}`,
    {
      data: {
        type: order.payment_method.payment_source_type,
        id: order.payment_source.id,
        attributes: paymentSourceAttributes,
      },
    }
  );

  let attributes =
    paymentSourceAttributesMap[order.payment_method.payment_source_type];
  let normalizedPaymentSource = normalize(data).get(attributes);

  return normalizedPaymentSource;
};
const refreshPaymentSource = async (
  paymentSource: any,
  paymentMethod: CommerceLayerPaymentMethod
): Promise<{ id: string; attributes: Record<string, any> } | null> => {
  try {
    const { data } = await ApiClient.patch(
      `/${paymentMethod.payment_source_type}/${paymentSource.id}`,
      {
        data: {
          id: paymentSource.id,
          type: paymentMethod.payment_source_type,
          attributes: {
            _refresh: true,
          },
        },
      }
    );
    return data?.data;
  } catch (error) {
    console.error("refreshPaymentSource", error);
  }

  return null;
};

const placeOrder = async (
  order: CommerceLayerOrder
): Promise<CommerceLayerOrder> => {
  try {
    const { data } = await ApiClient.patch(
      "/orders/" + order.id + "?include=" + orderIncludes.join(","),
      {
        data: {
          type: "orders",
          id: order.id,
          attributes: {
            _place: 1,
          },
        },
      }
    );

    return normalizedOrder(order, data);
  } catch (error) {
    console.error("placeOrder", error);
  }

  // throw error
  return Promise.reject(new Error("Place order failed."));
};

const createOrder = () => {
  return ApiClient.post("/orders", {
    data: {
      type: "orders",
      attributes: {},
    },
  })
    .then((response) => {
      return normalizedOrder(null, response);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
const createLineItem = async (
  quantity: number,
  orderid: string,
  skuCode: string,
  metadata?: Record<string, any>
) => {
  try {
    const { data } = await ApiClient.post("/line_items", {
      data: {
        type: "line_items",
        attributes: {
          quantity,
          sku_code: skuCode,
          metadata,
        },
        relationships: {
          order: {
            data: {
              type: "orders",
              id: orderid,
            },
          },
        },
      },
    });

    return data?.data;
  } catch (error) {
    console.error(serviceLog(serviceName, "createLineItem"), error);
  }

  return null;
};

const updateLineItem = async (id: string, quantity: number) => {
  try {
    const { data } = await ApiClient.patch(`/line_items/${id}`, {
      data: {
        id,
        type: "line_items",
        attributes: {
          quantity,
        },
      },
    });

    return data?.data;
  } catch (error) {
    console.error(serviceLog(serviceName, "updateLineItem"), error);
  }
  return null;
};
const deleteLineItem = (id: string) => {
  return ApiClient.delete(`/line_items/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
const getSkuPrice = async (
  skuCode: string
): Promise<{ attributes: { formatted_amount: string } } | null> => {
  try {
    const sku = await getSku(skuCode);

    const price = sku?.included
      ? (sku.included[0] as { attributes: { formatted_amount: string } })
      : null;

    return price;
  } catch (error) {
    console.error(serviceLog(serviceName, "getSkuPrice"), error);
  }

  return null;
};
const getSku = async (
  skuCode: string
): Promise<{ data: any; included?: any[] } | null> => {
  try {
    const { data }: { data: { data: { id: string }[]; included: any[] } } =
      await ApiClient.get(`/skus?filter[q][code_eq]=${skuCode}&include=prices`);

    return data;
  } catch (error) {
    console.error(serviceLog(serviceName, "getSku"), error);
  }

  return null;
};
const getSkus = () => {
  return ApiClient.get(`/skus`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  handleCustomerSubscription,
  getOrder,
  getOrder2,
  getCustomerAddresses,
  getCustomerPaymentSources,
  updateOrder,
  updateOrderAddresses,
  updateShipmentShippingMethod,
  updateOrderPaymentMethod,
  getOrderPaymentSource,
  createOrderPaymentSource,
  updateOrderPaymentSource,
  refreshPaymentSource,
  placeOrder,
  getSkus,
  getSku,
  getSkuPrice,
  createOrder,
  createLineItem,
  updateLineItem,
  deleteLineItem,
};
