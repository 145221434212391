import _ from "lodash";
// @ts-ignore
import normalize from "json-api-normalize";
import { orderAttributes, orderDefaults } from "./LegacyService/attributes";
import { CommerceLayerOrder } from "./OrderService/types";

export const normalizedOrder = (
  order: CommerceLayerOrder | null,
  data: any
): CommerceLayerOrder => {
  let nOrder = normalize(data).get(orderAttributes);
  return _.defaults(nOrder, orderDefaults(order));
};
