import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../components/shared/Button";
import { addToCart } from "../../../../redux/ecommerce/actions";
import CommerceLayerApi from "../../../../services/CommerceLayerApi";
import { DatoCmsProductBlock } from "../../../types";

interface Props {
  product: DatoCmsProductBlock;
}

export default function ProductItem({ product }: Props) {
  const dispatch = useDispatch<any>();

  const [adding, setAdding] = React.useState<boolean>(false);
  const [sku, setSku] = React.useState<any | null>(null);

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const res = await CommerceLayerApi.LegacyService.getSku(product.skuCode);
    if (res) {
      const { data, included } = res;
      const sku = data[0];
      const price = included ? included[0] : null;
      sku.price = price?.attributes;
      setSku(sku);
    }
  };

  const handleAddToCartClick = async () => {
    setAdding(true);
    await dispatch(addToCart(product.skuCode, 1, 100));
    setAdding(false);
  };

  return (
    <>
      <div className="p-4 border min-w-52 min-h-20">
        {sku != null ? (
          <div>
            <label>{sku.attributes.name}</label>

            <div className="text-center text-xl font-bold py-2">
              {sku.price?.formatted_amount}
            </div>

            <div className="flex justify-center">
              <Button
                className="bg-primary border-primary w-[135px] flex justify-center"
                onClick={handleAddToCartClick}
              >
                {adding ? (
                  <Icon path={mdiLoading} spin size={1} />
                ) : (
                  "Book"
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Icon path={mdiLoading} spin size={1} />
          </div>
        )}
      </div>
    </>
  );
}
