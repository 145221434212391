import React from "react";
import Container from "../../../components/shared/Container";
import ReactMarkdown from "react-markdown";
import {
  DatoCmsContentBase,
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
} from "../../types";

import { StructuredText } from "react-datocms";

type Props = {
  section: DatoCmsContentMediaBlock | DatoCmsListBlock | DatoCmsContentBase;
};

export default function PageHeader({ section }: Props): JSX.Element {
  const text = section?.stringContent ? (
    <ReactMarkdown>{section?.stringContent}</ReactMarkdown>
  ) : (
    <>
      {section?.content?.value ? (
        <StructuredText data={section.content} />
      ) : null}
    </>
  );

  return (
    <Container>
      <div>
        <h1 className="inline-block text-2xl font-bold md:text-4xl border-b-4 mb-4 border-primary">
          {section.title}
        </h1>
      </div>
      <h3 className="text-xl md:text-3xl">{section.subTitle}</h3>
      {text ? <div className="mt-2">{text}</div> : null}
    </Container>
  );
}
