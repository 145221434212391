import React from "react";
import { StructuredText } from "react-datocms";

export type TestimonialItemType = {
  title: string;
  content: any;
};

export default function TestimonialItem({
  item,
}: {
  item: TestimonialItemType;
}) {
  return (
    <>
      <div className="p-5 border rounded mb-5">
        <div className="mb-3">
          <StructuredText data={item.content} />
        </div>
        <h2 className="font-bold">{item.title}</h2>
      </div>
    </>
  );
}
