import React from "react";
import Container from "../../../components/shared/Container";
import EventGroupView from "../../../components/waterTaxi/EventGroupView";
import { DatoCmsEventBooking } from "../../types";

import { StructuredText } from "react-datocms";

type Props = {
  section: DatoCmsEventBooking;
};

export default function DefaultEvent({ section }: Props) {
  return (
    <Container>
      <h2 className="text-center text-2xl font-bold text-primary uppercase">
        {section.title}
      </h2>
      {section.content ? <StructuredText data={section.content} /> : null}
      <EventGroupView eventGroupId={section.eventGroup} />
    </Container>
  );
}
