import React from "react";
import Container from "../../../components/shared/Container";
import { DatoCmsProductListBlock } from "../../types";
import ProductItem from "./ProductItem";

interface Props {
  section: DatoCmsProductListBlock;
}

export default function ProductList({ section }: Props) {
  return (
    <Container>
      <h2 className="text-2xl font-bold text-center">{section.title}</h2>

      <div className="flex justify-center mt-3 -mx-3">
        {section.products?.map((product) => (
          <div className="px-3">
            <ProductItem product={product} />
          </div>
        ))}
      </div>
    </Container>
  );
}
