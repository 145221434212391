import { ApiClient, serviceLog } from "..";
import { normalizedOrder } from "../utils";
import { orderIncludes } from "./constants";
import { CommerceLayerOrder } from "./types";

const serviceName = "OrderService";

const get = async (id: string) => {
  try {
    const { data }: { data: { data: CommerceLayerOrder } } =
      await ApiClient.get(`/orders/${id}?include=${orderIncludes.join(",")}`);
    return normalizedOrder(data.data, data);
  } catch (error) {
    console.error(serviceLog(serviceName, "get"), error);
  }

  return null;
};
const create = async (): Promise<CommerceLayerOrder | null> => {
  try {
    const { data }: { data: { data: CommerceLayerOrder } } =
      await ApiClient.post("/orders", {
        data: {
          type: "orders",
          attributes: {},
        },
      });

    return data.data;
  } catch (error) {
    console.error(serviceLog(serviceName, "create"), error);
  }

  return null;
};

const OrderService = {
  get,
  create,
};
export default OrderService;
