import React, { useEffect } from "react";
import WaterTaxiApi from "../../../services/WaterTaxiApi";
import { EventBooking } from "../../../services/WaterTaxiApi/EventBookingService/types";
import Container from "../../shared/Container";
import EventDayContent from "./EventDayContext";
import EventDays from "./EventDays";
import { GroupedEvent } from "./types";

type Props = {
  eventGroupId: string;
};

export default function EventGroupView({ eventGroupId }: Props) {
  const [events, setEvents] = React.useState<EventBooking[] | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<GroupedEvent | null>(
    null
  );

  const groupedEvents: GroupedEvent[] = React.useMemo(() => {
    const grouped: GroupedEvent[] = [];

    events?.forEach((event) => {
      const dateStr = event.from.substring(0, 10);
      const existingGroup = grouped.find((group) => group.dateStr === dateStr);
      if (existingGroup) {
        existingGroup.events.push(event);
      } else {
        grouped.push({ dateStr, events: [event] });
      }
    });

    return grouped;
  }, [events]);

  useEffect(() => {
    loadEvents();
  }, [eventGroupId]);

  const loadEvents = async () => {
    const events = await WaterTaxiApi.EventGroupService.getEventGroupEvents(
      eventGroupId
    );
    setEvents(events);
  };

  return (
    <>
      {groupedEvents && (
        <>
          <EventDays
            eventGroupId={eventGroupId}
            groupedEvents={groupedEvents}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <EventDayContent
            eventGroupId={eventGroupId}
            groupedEvents={groupedEvents}
            selectedDate={selectedDate}
          />
        </>
      )}
    </>
  );
}
