import React from "react";
import { navigate } from "gatsby";
import { StructuredText } from "react-datocms";
import Button from "../../../components/shared/Button";
import Container from "../../../components/shared/Container";
import { DatoCmsContentMediaBlock } from "../../types";

interface Props {
  section: DatoCmsContentMediaBlock;
  images: any[];
}

export default function VideoBackground({ section, images }: Props) {
  const isMobile =
    typeof window === "undefined" ? true : window.innerWidth < 768;

  let bg: null | { name: string; publicURL: string } = images.find((i) =>
    isMobile ? i.name === "land-mobile" : i.name === "land-blur"
  );

  return (
    <>
      {!isMobile && (
        <video
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            height: "56.25vw",
            minHeight: "100vh",
            minWidth: "177.77vh",
            zIndex: 25,
          }}
          muted
          autoPlay
          loop
        >
          <source src={section.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <div
        className="absolute top-0 right-0 w-full h-full z-20"
        style={{
          backgroundImage: `url(${bg?.publicURL})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>

      <div
        className="absolute top-0 right-0 w-full h-full flex items-center z-30 text-white text-center md:text-start"
        style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
      >
        <Container>
          <h1 className="text-6xl font-bold">
            <span className="inline-block border-b-0 border-primary pb-2 md:border-b-4">
              {section.title}
            </span>
          </h1>

          <div className="mt-5">
            <Button className="bg-primary border-primary text-dark mx-2" onClick={() => navigate("https://app.utrib.club")}>
              Join the Club
            </Button>
            <Button onClick={() => navigate("/#HowItWorks")} className="mx-2">
              Learn More
            </Button>
          </div>

          {section?.content?.value ? (
            <div className="mt-5 md:text-2xl ">
              <StructuredText data={section.content} />
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
}
