import { navigate } from "gatsby";
import React from "react";
import { StructuredText } from "react-datocms";
import { DatoCmsContentBase } from "../../types";

interface Props {
  block: DatoCmsContentBase;
}

export default function ServiceBlock({ block }: Props) {
  const handleClick = () => {
    if (block.linkUrl) navigate(block.linkUrl);
  };

  return (
    <div
      className={`${block.linkUrl ? "cursor-pointer" : ""}`}
      onClick={handleClick}
    >
      <div className="flex flex-col text-center -mx-3 md:flex-row md:text-start md:items-center">
        <div className="px-3 flex items-center">
          <img title={block.title} className="max-h-[70px] mx-auto mb-3" src={block.url} />
        </div>
        <div className="px-3">
          <h2 className="text-xl font-bold">{block.title}</h2>

          {block?.content ? <StructuredText data={block.content} /> : null}

        </div>
      </div>
    </div>
  );
}
