import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { EcommerceState } from "../../../redux/ecommerce/reducer";
import { removeSnackbar } from "../../../redux/ecommerce/actions";

export default function GlobalSnackbar() {
  const dispatch = useDispatch<any>();

  const snackbars = useSelector<RootState, EcommerceState["snackbar"]>(
    (state: RootState) => state.ecommerce.snackbar
  );

  const handleClose = (id: number) => {
    dispatch(removeSnackbar(id));
  };

  return (
    <>
      {snackbars ? (
        <>
          {snackbars?.map((snackbar, index) => (
            <Snackbar
              key={index}
              open={snackbar.open}
              autoHideDuration={5000}
              onClose={() => handleClose(snackbar.id)}
              message={snackbar.message}
            />
          ))}
        </>
      ) : null}
    </>
  );
}
