import React, { useState } from "react";
import { FAQ } from "./types";

type Props = {
  faq: FAQ;
};

export default function FAQItem({ faq }: Props) {
  const [vis, setVis] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setVis(!vis);
  };

  return (
    <>
      <div key={faq.question} className={"border-l-4 border-primary bg-grey mb-4"}>
        <div
          onClick={onClick}
          className={`block cursor-pointer ${
            vis ? "bg-primary text-white" : "text-black"
          } font-bold px-3 py-4`}
        >
          <h3>{faq.question}?</h3>
        </div>

        {vis ? (
          <div
            className={"p-3"}
            dangerouslySetInnerHTML={{ __html: faq.answer }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
