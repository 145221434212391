import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ariaLabel?: string;
};

export default function Button({
  children,
  className = "bg-primary border-primary text-dark",
  disabled,
  onClick,
}: Props) {
  disabled = disabled === null ? false : disabled;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${
        disabled ? "opacity-50" : ""
      } inline-block py-2 px-3 border font-bold rounded`}
    >
      {children}
    </button>
  );
}
