import React from "react";
import {
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
  DatoCmsPage,
} from "../types";

import TemplateHelper, { TemplateSectionClasses } from "./templates";

type Props = {
  key: string;
  section: DatoCmsContentMediaBlock | DatoCmsListBlock;
  pageContext: any;
  images: any;
};

export default function Section({ section, pageContext, images }: Props) {
  const template =
    section?.template == undefined || section.template == ""
      ? "DefaultTemplate"
      : section.template;

  const additionalClass = TemplateSectionClasses[template] || "";

  const Component = TemplateHelper[template];

  if (!Component) {
    return <>No component found</>;
  }

  return (
    <section className={`${section.sectionClass} ${additionalClass}`}>
      <div id={section.sectionId} className="relative top-[-90px]"></div>
      {React.cloneElement(<Component key={section.id} />, {
        section,
        pageContext,
        images,
      })}
    </section>
  );
}
