import React from "react";
import Container from "../../../components/shared/Container";
import {
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
  DatoCmsTestimonialBlock,
} from "../../types";

import TestimonialItem from "./TestimonialItem";

type Props = {
  section: DatoCmsContentMediaBlock | DatoCmsListBlock;
};

export default function Testimonials({ section }: Props): JSX.Element {
  const { testimonials } = section as DatoCmsTestimonialBlock;

  return (
    <>
      <Container>
        {testimonials &&
          testimonials.map((item) => <TestimonialItem item={item} />)}
      </Container>
    </>
  );
}
