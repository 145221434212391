import React from "react";
import Ticket from "../Ticket";
import { GroupedEvent } from "../types";

type Props = {
    eventGroupId: string;
    groupedEvents: GroupedEvent[];
    selectedDate: GroupedEvent | null;
};

export default function EventDayContent({ eventGroupId, groupedEvents, selectedDate }: Props) {
    return (
        <React.Fragment>
            {groupedEvents.map(eventDate => (
                <div key={eventDate.dateStr} className={`${selectedDate?.dateStr !== eventDate.dateStr && "hidden"}`}>
                    {eventDate.events.map(event => (
                        <Ticket
                            key={event.id}
                            dateStr={eventDate.dateStr+event.id}
                            eventGroupId={eventGroupId}
                            eventBooking={event}
                        />
                    ))}
                </div>
            ))}
        </React.Fragment>
    );
}
