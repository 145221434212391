import React, { useMemo } from "react";

import { GroupedEvent } from "../types";

type Props = {
  eventGroupId: string;
  groupedEvents: GroupedEvent[];
  selectedDate: GroupedEvent | null;
  setSelectedDate: (date: GroupedEvent) => void;
};

function EventGroupHeading({ eventGroupId, dateStr, selected, onClick }: any) {
  const date = new Date(dateStr);

  const topText = useMemo(() => {
    const month = new Date(dateStr).toLocaleDateString("en-GB", {
      month: "short",
    });
    return date.toLocaleDateString("en-GB", { day: "numeric" }) + " " + month;
  }, [dateStr]);

  const bottomText = useMemo(() => {
    return date.toLocaleDateString("en-GB", { weekday: "long" });
  }, [dateStr]);

  return (
    <div
      className="px-3 cursor-pointer shrink-0 basis-[150px]"
      onClick={onClick}
    >
      <div
        key={eventGroupId + "_" + dateStr}
        className={`px-5 py-3 border border-b-4 text-center ${
          selected && "border-b-green-500"
        }`}
      >
        <h2>
          <span className="text-2xl font-bold">{topText}</span>
          <small className="text-lg block">{bottomText}</small>
        </h2>
      </div>
      <div className="flex justify-center">
        <div className="w-7 overflow-hidden inline-block rotate-180">
          <div
            className={`h-5 w-5 bg-gray-200 rotate-45 transform origin-bottom-left ${
              selected && "bg-green-500"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default function EventDays({
  eventGroupId,
  groupedEvents,
  selectedDate,
  setSelectedDate,
}: Props) {
  return (
    <div className="overflow-x-auto mb-3">
      <div className="flex justify-start md:justify-center mb-2">
        {groupedEvents.map((day) => (
          <EventGroupHeading
            key={day.dateStr}
            eventGroupId={eventGroupId}
            dateStr={day.dateStr}
            selected={selectedDate?.dateStr === day.dateStr}
            onClick={() => setSelectedDate(day)}
          />
        ))}
      </div>
    </div>
  );
}
