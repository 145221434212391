import { useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { FormField } from "../types";

import { ObjectSchema } from "yup";
interface Props {
  field: FormField;
  sectionData: any;
  onChange: (value: string) => void;
  validationErrors: { name: string; message: string }[];
}

export default function SiteFormField({
  field,
  sectionData,
  onChange,
  validationErrors,
}: Props) {

  const [touched, setTouched] = React.useState<boolean>(false);

  const value: string = useMemo(() => {
    if (!sectionData || !field.name) return "";
    return sectionData[field.name] ?? "";
  }, [field.name, sectionData]);

  const validationError = useMemo(() => {
    if(!validationErrors) return null;
    const error = validationErrors.find((ve) => ve.name === field.name);
    if (error) return error.message;
    return null;
  }, [validationErrors]);

  const handleOnChange = (event: any) => {
    const value = event.target.value;

    setTouched(true);

    switch (field.type) {
      default:
        onChange(value);
        break;
    }
  };

  const handleBlur = () => {
    setTouched(true);
  };

  switch (field.type) {
    case "textarea":
      return (
        <TextField
          label={field.label}
          variant="filled"
          value={value}
          onChange={handleOnChange}
          onBlur={handleBlur}
          error={touched && validationError != null}
          fullWidth
          multiline
          InputLabelProps={{ shrink: field?.shrink }}
          rows={4}
        />
      );
    case "select":
      return (
        <FormControl variant="filled" fullWidth>
          <InputLabel>{field.label}</InputLabel>
          <Select
            value={value}
            onChange={handleOnChange}
            onBlur={handleBlur}
            error={touched && validationError != null}
          >
            {field.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label || option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    default:
      return (
        <TextField
          label={field.label}
          variant="filled"
          value={value}
          onChange={handleOnChange}
          onBlur={handleBlur}
          error={touched && validationError != null}
          fullWidth
          type={field.type}
          InputLabelProps={{ shrink: field?.shrink }}
        />
      );
  }
}
