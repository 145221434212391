import React from "react";
import Container from "../../../components/shared/Container";
import {
  DatoCmsContentBase,
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
} from "../../types";

import LazyLoad from "react-lazy-load";

import { StructuredText } from "react-datocms";

type Props = {
  section: DatoCmsContentMediaBlock;
};

export default function SectionWithVideo({ section }: Props): JSX.Element {
  const text = section?.stringContent ? (
    <p>{section?.stringContent}</p>
  ) : (
    <>
      {section?.content?.value ? (
        <StructuredText data={section.content} />
      ) : null}
    </>
  );

  return (
    <Container className="max-w-screen-xl">
      <div className="text-center">
        <h2 className="inline-block text-2xl font-bold md:text-4xl border-b-4 mb-6 border-primary">
          {section.title}
        </h2>
        <h3 className="text-xl md:text-2xl uppercase mb-2">
          {section.subTitle}
        </h3>
      </div>

      <div className="flex flex-wrap items-center -mx-3">
        <div className="px-3 w-full md:w-5/12">
          <div className="max-w-[500px] md:max-w-[850px] mx-auto rounded overflow-hidden my-10">
            <div className="relative pt-[56%]">
              <LazyLoad>
                <iframe
                  title="Urban Truant RIB Club Video"
                  className="absolute top-0 left-0 w-full h-full"
                  src={section.url}
                />
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className="px-3 w-full md:w-7/12 md:px-[100px]">
          {text ? <div className="mt-2">{text}</div> : null}
        </div>
      </div>
    </Container>
  );
}
