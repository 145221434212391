import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import GlobalSnackbar from "./Snackbar";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <main className="pt-[60px]">{children}</main>
      <GlobalSnackbar />
      <Footer />
    </>
  );
}
