import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSnackbar, addToCart } from "../../../../redux/ecommerce/actions";
import CommerceLayerApi from "../../../../services/CommerceLayerApi";
import { EventBooking } from "../../../../services/WaterTaxiApi/EventBookingService/types";
import CurrencyHelper from "../../../../utils/currency";
import Button from "../../../shared/Button";
import QuantitySelector from "../../../shared/QuantitySelector";

type Props = {
  eventGroupId: string;
  eventBooking: EventBooking;
  dateStr: string;
};

export default function Ticket({ eventGroupId, eventBooking, dateStr }: Props) {
  const dispatch = useDispatch<any>();

  const [addingToCart, setAddingToCart] = React.useState(false);
  const [dynamicLoading, setDynamicLoading] = React.useState(true);

  const [quantity, setQuantity] = React.useState(1);
  const [price, setPrice] = React.useState<string | null>(null);

  const prefix = `${eventGroupId}_${dateStr}`;

  const fromTime = new Date(eventBooking.from).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const toTime = new Date(eventBooking.to).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const fullyBooked = useMemo(() => {
    return eventBooking.available <= 0;
  }, [eventBooking.available]);

  const loadPrice = async () => {
    setDynamicLoading(true);

    const price = await CommerceLayerApi.LegacyService.getSkuPrice(
      eventBooking?.skuId
    );

    if (price) {
      setPrice(price?.attributes?.formatted_amount || "");
    }

    setDynamicLoading(false);
  };

  const handleAddToCartClick = async () => {
    setAddingToCart(true);

    const available = eventBooking.available;

    const response = await dispatch(
      addToCart(eventBooking.skuId, quantity, available, {
        eventBooking: eventBooking,
      })
    );

    if (response?.message) {
      dispatch(addSnackbar(response.message));
    }

    setTimeout(() => {
      setAddingToCart(false);
    }, 100);
  };

  React.useEffect(() => {
    loadPrice();
  }, []);

  return (
    <div
      key={`${prefix}_${eventBooking.id}`}
      data-id={eventBooking.id}
      className="p-3 mb-5 shadow-sm border"
      style={{ opacity: fullyBooked ? 0.5 : 1 }}
    >
      <div className="flex flex-wrap items-center md:flex-row-reverse">
        <div className="w-full md:w-auto">
          <div className="flex items-center justify-between -mx-2">
            <div className="px-2">
              {!fullyBooked ? (
                <QuantitySelector
                  value={quantity}
                  setValue={setQuantity}
                  max={eventBooking.available < 8 ? eventBooking.available : 8}
                />
              ) : null}
              <span
                className={`block text-2xl font-bold ${
                  dynamicLoading && "opacity-50"
                } `}
              >
                {price}
              </span>
            </div>

            <div className="px-2">
              <Button
                disabled={fullyBooked}
                onClick={handleAddToCartClick}
                className="bg-primary border-primary w-[135px] flex justify-center"
              >
                {fullyBooked ? (
                  "Fully Booked"
                ) : (
                  <>
                    {addingToCart ? (
                      <Icon size={1} path={mdiLoading} spin={0.8} />
                    ) : (
                      "Book"
                    )}
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full md:flex-1 md:w-auto mt-4  md:mt-0">
          {/* Times */}
          <div className="flex items-center">
            <div>
              <div className="text-xs md:text-base">
                {eventBooking.location}
              </div>
              <div className="font-bold md:text-lg">{fromTime}</div>
            </div>
            <div className="px-3 basis-20">
              <span className="block bg-gray-300 h-px w-full"></span>
            </div>
            <div>
              <div className="text-xs md:text-base">
                {eventBooking.locationTo}
              </div>
              <div className="font-bold md:text-lg">{toTime}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
