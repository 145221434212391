import React, { useMemo } from "react";
import Button from "../Button";
import SiteFormSection from "./Section";
import { FormConfig } from "./types";

import * as yup from "yup";
import SiteFormField from "./Field";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
interface Props {
  data: Record<string, any>;
  setData: (data: Record<string, any>) => void;
  configuration: FormConfig;
  onSubmit: (data: Record<string, any>) => void;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
}

export default function SiteForm({
  data,
  setData,
  configuration,
  onSubmit,
  loading,
  setLoading,
}: Props) {
  const yupObject: any = {};
  for (const section of configuration.sections) {
    for (const field of section.fields) {
      yupObject[field.name] = field.yup;
    }
  }
  const SCHEMA = yup.object().shape(yupObject);

  const validationErrors = useMemo(() => {
    if (!SCHEMA || !data || Object.keys(data).length < 1) return [];

    const _data = Object.values(data).reduce(
      (acc, val) => ({ ...acc, ...val }),
      {}
    );

    let messages: { name: string; message: string }[] = [];
    try {
      SCHEMA.validateSync(_data, {
        abortEarly: false,
      });
    } catch (err: any) {
      messages = err.errors.map(
        (err: { name: string; message: string }) => err
      );
    }

    return messages;
  }, [SCHEMA, data]);

  const handleOnChange = (
    sectionName: string,
    fieldName: string,
    value: string
  ) => {
    const _value = value != null && value != "" ? value : undefined;

    setData({
      ...data,
      [sectionName]: {
        ...(data[sectionName] || {}),
        [fieldName]: _value,
      },
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setLoading && setLoading(true);

    // map all sections to one data
    const formData: Record<string, any> = mapData(data);

    let messages: { name: string; message: string }[] = [];
    try {
      SCHEMA.validateSync(formData, {
        abortEarly: false,
      });
    } catch (err: any) {
      messages = err.errors.map(
        (err: { name: string; message: string }) => err
      );
    }

    if (messages.length < 1) {
      onSubmit(formData);
    } else {
      setTimeout(() => {
        setLoading && setLoading(false);
      }, 200);
    }
  };

  const mapData = (data: Record<string, any>) => {
    return Object.values(data).reduce((acc, val) => ({ ...acc, ...val }), {});
  };

  return (
    <form>
      {configuration.sections.map((section) => (
        <SiteFormSection key={section.name} section={section}>
          {section.fields.map((field) => (
            <div
              key={section.name + "_" + field.name}
              className={`px-3 ${
                field.className ? field.className : "w-full"
              } my-2`}
            >
              <SiteFormField
                key={field.name}
                field={field}
                sectionData={data[section.name]}
                onChange={(value: string) =>
                  handleOnChange(section.name, field.name, value)
                }
                validationErrors={validationErrors}
              />
            </div>
          ))}
        </SiteFormSection>
      ))}

      <div className="flex justify-end mt-4">
        <Button
          className="bg-primary border-primary w-[120px] flex justify-center"
          onClick={handleSubmit}
          disabled={loading || validationErrors.length > 0}
        >
          {loading ? <Icon path={mdiLoading} spin={1} size={1} /> : "Submit"}
        </Button>
      </div>
    </form>
  );
}
