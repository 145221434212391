import React, { useMemo } from "react";
import SiteFormField from "../Field";
import { FormSection } from "../types";

interface Props {
  section: FormSection;
  children: React.ReactNode | React.ReactNode[];
}

export default function SiteFormSection({ section, children }: Props) {
  return (
    <section key={section.title} className="flex flex-wrap pt-3 -mx-3">
      {section != undefined && (
        <div className="px-3 w-full">
          <h2>{section.title}</h2>
        </div>
      )}
      {children}
    </section>
  );
}
