export const orderIncludes = [
  "line_items",
  "billing_address",
  "shipping_address",
  "shipments.shipment_line_items.line_item",
  "shipments.available_shipping_methods",
  "shipments.shipping_method",
  "available_payment_methods",
  "payment_method",
  "payment_source",
  "customer",
];
