import React from "react";
import FacebookPixelScript from "./FacebookPixel";
import HelpScoutScripts from "./HelpScout";

export default function FooterScripts() {
  return (
    <>
      <HelpScoutScripts id="35f38f6a-4829-4595-a912-4b728b858fc8" />
      {/* <FacebookPixelScript /> */}
    </>
  );
}
