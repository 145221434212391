import { FormConfig } from "../../../components/shared/Form/types";

import * as yup from "yup";

export const HUT_FORM: FormConfig = {
  name: "footer",
  sections: [
    {
      name: "General",
      fields: [
        {
          name: "firstName",
          type: "text",
          label: "First Name",
          yup: yup.string().required({
            message: "First Name is required",
            name : "firstName"
          }),
        },
        {
          name: "lastName",
          type: "text",
          label: "Last Name",
          yup: yup.string().required({
            message: "Last Name is required",
            name : "lastName"
          }),
        },
        {
          name: "email",
          type: "email",
          label: "Email",
          yup: yup.string().required({
            message: "Email is required",
            name : "email"
          }),
        },
        {
          name: "phoneNumber",
          type: "text",
          label: "Phone Number",
          yup: yup.string().required({
            message: "Phone Number is required",
            name : "phoneNumber"
          }),
        },
      ],
    },
    {
      name: "Booking",
      title: "Booking Details",
      fields: [
        {
          name: "date",
          type: "date",
          label: "Date",
          className: "w-full md:w-1/2",
          shrink: true,
          yup: yup.string().required({
            message: "Date is required",
            name : "date"
          }),
        },
        {
          name: "time",
          type: "time",
          label: "Time",
          className: "w-full md:w-1/2",
          shrink: true,
          yup: yup.string().required({
            message: "Time is required",
            name : "time"
          }),
        },
        {
          name: "pickupLocation",
          type: "select",
          label: "Pickup Location",
          className: "w-full md:w-1/2",
          yup: yup.string().required({
            message: "Pickup Location is required",
            name : "pickupLocation"
          }),
          options: [
            {
              value: "Hamble (Swanwick, Warsash or Hamble Town Quay)",
            },
            {
              value: "Cowes (West or East Cowes)",
            },
            {
              value: "Lymington (Town Quay)",
            },
            {
              value: "Portsmouth (Gunwharf Quay or Gosport Marina)",
            },
            {
              value: "Southampton (Town Quay or Ocean Village)",
            },
          ],
        },
        {
          name: "pax",
          type: "number",
          label: "Number of Passengers (max 22)",
          className: "w-full md:w-1/2",
          yup: yup.number().max(22, {
            message: "Maximum number of passengers is 22",
            name : "pax"
          }).required({
            message: "Number of Passengers is required",
            name : "pax"
          }),
        },
        {
          name: "message",
          type: "textarea",
          label: "Message",
        },
      ],
    },
  ],
};
