import {
  SET_ORDER,
  SET_LOADING,
  SET_COUNTRIES,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_SOURCE,
  ADD_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_ORDER_LINE_ITEMS,
} from "./constants";

import CommerceLayerApi from "../../services/CommerceLayerApi";
import { AppDispatch } from "../store";
import {
  CommerceLayerOrder,
  CommerceLayerOrderLineItem,
} from "../../services/CommerceLayerApi/OrderService/types";
import { Customer } from "../../services/WaterTaxiApi/CustomerService/types";
import WaterTaxiApi from "../../services/WaterTaxiApi";
import { SnackbarType } from "./reducer";

// CART

export const addToCart =
  (
    skuCode: string,
    quantity: number,
    available: number,
    metadata?: Record<string, any>
  ) =>
  async (dispatch: any): Promise<{ success: boolean; message: string }> => {
    if (quantity > available) {
      return {
        success: false,
        message: `There are only ${available} available.`,
      };
    }

    const order = await dispatch(getOrder());

    if (order) {
      var existing = order?.line_items?.find(
        (li: any) =>
          li.sku_code == skuCode &&
          (metadata?.eventBooking?.id
            ? li.metadata?.eventBooking.id == metadata?.eventBooking?.id
            : true)
      );

      let response;
      if (existing) {
        if (existing.quantity + quantity > available) {
          return {
            success: false,
            message: `There are only ${available} available.`,
          };
        }

        response = await CommerceLayerApi.LegacyService.updateLineItem(
          existing.id,
          existing.quantity + quantity
        );
      } else {
        response = await CommerceLayerApi.LegacyService.createLineItem(
          quantity,
          order.id,
          skuCode,
          metadata
        );
      }

      // load order
      dispatch(loadOrder(order.id));

      return { success: true, message: "Item added." };
    }

    return { success: false, message: "Something went wrong." };
  };
export const removeFromCart = (lineItemId: string) => async (dispatch: any) => {
  const order = await dispatch(getOrder());

  if (order) {
    var existing = order?.line_items?.find((li: any) => li.id == lineItemId);
    if (existing) {
      await CommerceLayerApi.LegacyService.deleteLineItem(existing.id);
      dispatch(loadOrder(order.id));
      return true;
    }
  }
  return false;
};

// CUSTOMER

// ADDRESS

export const setBillingAddress =
  (address: CommerceLayerOrder["billing_address"]) => async (dispatch: any) => {
    const order = await dispatch(getOrder(false));

    if (order) {
      const returnOrder =
        await CommerceLayerApi.LegacyService.updateOrderAddresses({
          ...order,
          billing_address: address,
        });
      if (returnOrder) {
        dispatch(loadOrder(order.id));
        return order;
      }
    }

    return null;
  };

export const setOrderCustomer =
  (customer: Customer) => async (dispatch: any) => {
    const order = await dispatch(getOrder());
    if (order) {
      await CommerceLayerApi.LegacyService.updateOrder(order, {
        customer_email: customer.email,
        metadata: {
          ...(order.metadata || {}),
          customer_id: customer.id,
        },
      });
      dispatch(loadOrder(order.id));
      return true;
    }
    return false;
  };

// PAYMENT

export const setOrderPaymentMethod =
  (paymentMethod: CommerceLayerOrder["payment_method"]) =>
  async (dispatch: any): Promise<CommerceLayerOrder | null> => {
    let order = await dispatch(getOrder());
    if (order?.id && paymentMethod?.id) {
      const newPaymentMethod =
        await CommerceLayerApi.LegacyService.updateOrderPaymentMethod(
          order,
          paymentMethod
        );

      dispatch({ type: SET_PAYMENT_METHOD, payload: newPaymentMethod });
    }

    return null;
  };

export const setOrderPaymentSource =
  (paymentSourceAttributes: any) => async (dispatch: any) => {
    const order = await dispatch(getOrder());

    if (order) {
      const paymentSource =
        await CommerceLayerApi.LegacyService.createOrderPaymentSource(
          order,
          order.payment_method,
          paymentSourceAttributes
        );

      dispatch({
        type: SET_PAYMENT_SOURCE,
        payload: paymentSource,
      });

      return true;
    }

    return false;
  };
// ORDER

export const placeOrder =
  () =>
  async (dispatch: any): Promise<boolean> => {
    try {
      let order = await dispatch(getOrder());
      if (order && order.payment_method) {
        await CommerceLayerApi.LegacyService.placeOrder(order);
        dispatch(setOrder(null));
        return true;
      }
    } catch (e) {
      console.error("placeOrder", e);
    }
    return false;
  };
export interface IssueType {
  type: "delete" | "update";
  lineItem: CommerceLayerOrderLineItem;
  diff: number;
  newQuantity: number;
}
export const checkAvailability =
  () =>
  async (dispatch: any): Promise<IssueType[]> => {
    let order: CommerceLayerOrder = await dispatch(getOrder());

    const lineItems: CommerceLayerOrderLineItem[] =
      order?.line_items?.filter((item) => item.item_type === "skus" && item.metadata?.eventBooking != null) || [];

    const promises = lineItems.map(
      async (lineItem: CommerceLayerOrderLineItem) => {
        const eventBooking = await WaterTaxiApi.EventBookingService.get(
          lineItem.metadata?.eventBooking?.id
        );

        const diff = eventBooking?.available - lineItem.quantity;

        const newQuantity = lineItem.quantity + diff;

        const type = newQuantity > 0 ? "update" : "delete";

        return { lineItem: lineItem, diff, type, newQuantity } as IssueType;
      }
    );

    const results = await Promise.all(promises);

    const issues = results.filter((result) => result.diff < 0);

    if (issues.length > 0) {
      for (const issue of issues) {
        // delete
        if (issue.type === "delete") {
          await CommerceLayerApi.LegacyService.deleteLineItem(
            issue.lineItem.id
          );
        }
        // update
        if (issue.type === "update") {
          await CommerceLayerApi.LegacyService.updateLineItem(
            issue.lineItem.id,
            issue.newQuantity
          );
        }
      }

      dispatch(loadOrderItems(order.id));
    }

    return issues;
  };

export const getOrder =
  (update: boolean = false) =>
  async (dispatch: any, getState: any) => {
    let { order }: { order: CommerceLayerOrder | null } = getState().ecommerce;

    let orderId: string | undefined = order?.id;

    if (!orderId) {
      orderId = (localStorage.getItem("order") as string) ?? null;
    }

    if (orderId) {
      order = await CommerceLayerApi.OrderService.get(orderId);
    } else {
      order = await CommerceLayerApi.OrderService.create();
      orderId = order?.id;
    }

    if (update && order) {
      await dispatch(setOrder(order));
    }

    return order;
  };
export const loadOrder = (id: string) => async (dispatch: any) => {
  const order = await CommerceLayerApi.OrderService.get(id);
  await dispatch(setOrder(order));
  return;
};
export const loadOrderItems = (id: string) => async (dispatch: any) => {
  const order = await CommerceLayerApi.OrderService.get(id);
  if (order) {
    await dispatch({ type: SET_ORDER_LINE_ITEMS, payload: order.line_items });
  }
  return order;
};
export const setOrder =
  (order: CommerceLayerOrder | null) => async (dispatch: AppDispatch) => {
    const orderId = order?.id ?? null;
    if (!orderId) {
      localStorage.removeItem("order");
    } else {
      localStorage.setItem("order", orderId);
    }
    return dispatch({ type: SET_ORDER, payload: order });
  };
export const setLoading =
  (loading: boolean) => async (dispatch: AppDispatch) => {
    dispatch({ type: SET_LOADING, payload: loading });
  };
export const setCountries =
  (countries: any) => async (dispatch: AppDispatch) => {
    return dispatch({ type: SET_COUNTRIES, payload: countries });
  };

export const addSnackbar =
  (message: string) => async (dispatch: AppDispatch) => {
    return dispatch({ type: ADD_SNACKBAR, payload: message });
  };
export const removeSnackbar = (id: number) => async (dispatch: AppDispatch) => {
  return dispatch({ type: REMOVE_SNACKBAR, payload: id });
};
