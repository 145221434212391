import React from "react";
import Container from "../../../components/shared/Container";
import { DatoCmsSectionBlock } from "../../types";
import TemplateHelper from "../templates";

interface Props {
  section: DatoCmsSectionBlock;
}

export default function SectionBlock({ section }: Props) {
  return (
    <Container>
      <div className={`flex flex-wrap -mx-4 ${section.rowClass}`}>
        {section.blocks?.map((block) => {
          const Component = TemplateHelper[block.template];

          if (!Component) return null;

          return (
            <div key={block.id} className={`px-4 ${block.sectionClass}`}>
              {React.cloneElement(<Component key={block.id} />, {
                block,
              })}
            </div>
          );
        })}
      </div>
    </Container>
  );
}
