import axios from "axios";
import {
  CLEAR_AUTH_ACCESS_TOKEN,
  UPDATE_AUTH_ACCESS_TOKEN,
  UPDATE_AUTH_REFRESH_TOKEN,
} from "../../redux/ecommerce/constants";

import store from "../../redux/store";

const CL_URL = process.env.GATSBY_APP_CL_BASEURL;
const CL_CLIENTID = process.env.GATSBY_APP_CL_CLIENTID;
const CL_SCOPE = "market:" + process.env.GATSBY_APP_CL_MARKET;

const AuthClient = axios.create({
  baseURL: CL_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const getAccessToken = async () => {
  let accessToken = localStorage.getItem("cl-token");

  if (accessToken) return accessToken;

  const resp = await AuthClient.post("/oauth/token", {
    grant_type: "client_credentials",
    client_id: CL_CLIENTID,
    scope: CL_SCOPE,
  });

  accessToken = resp?.data?.access_token;

  if (accessToken) {
    localStorage.setItem("cl-token", accessToken);
  }

  return accessToken;
};
const reAuthAccessToken = async () => {
  let accessToken = null;

  const resp = await AuthClient.post("/oauth/token", {
    grant_type: "client_credentials",
    client_id: CL_CLIENTID,
    scope: CL_SCOPE,
  });

  accessToken = resp?.data?.access_token;

  if (accessToken) {
    localStorage.setItem("cl-token", accessToken);
  }

  return accessToken;
};
const refreshAccessToken = (refreshToken: string) => {
  return AuthClient.post("/oauth/token", {
    grant_type: "refresh_token",
    client_id: CL_CLIENTID,
    refresh_token: refreshToken,
    scope: CL_SCOPE,
  })
    .then((response) => {
      store.dispatch({
        type: UPDATE_AUTH_ACCESS_TOKEN,
        payload: response.data.access_token,
      });
      store.dispatch({
        type: UPDATE_AUTH_REFRESH_TOKEN,
        payload: response.data.refresh_token,
      });
      return response.data.access_token;
    })
    .catch((error) => {
      console.error("Get access token error:", error.response);
    });
};
const updateAccessToken = () => {
  store.dispatch({ type: CLEAR_AUTH_ACCESS_TOKEN });

  const {
    ecommerce: { auth },
  } = store.getState();

  let refreshToken = auth?.refresh_token;
  if (refreshToken) {
    return refreshAccessToken(refreshToken);
  } else {
    return reAuthAccessToken();
  }
};

const CommerceLayerAuthService = {
  getAccessToken,
  updateAccessToken,
};

export default CommerceLayerAuthService;
