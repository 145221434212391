import React from "react";
import { Link, navigate } from "gatsby";

import Icon from "@mdi/react";
import { mdiClose, mdiMenu } from "@mdi/js";
import Container from "../../shared/Container";
import Button from "../../shared/Button";

export const HEADER_MENU = {
  items: [
    {
      name: "Home",
      slug: "/#Home",
    },
    {
      name: "How it Works",
      slug: "/#HowItWorks",
    },
    {
      name: "Pricing",
      slug: "/#Pricing",
    },
    {
      name: "Boats",
      slug: "/#Boats",
    },
    {
      name: "FAQs",
      slug: "/#FAQs",
    },
  ],
};

export default function Header() {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const gotToMembersArea = () => {
    if(window?.location?.href){
      window.location.href = "https://app.utrib.club/";
    }
  }

  return (
    <header className="fixed z-40 top-0 left-0 w-full bg-dark text-white">
      <Container>
        <div className="flex justify-between items-center -mx-5">
          <div className="px-5 flex">
            <Link
              className="flex items-center h-[60px] py-[15px] text-white hover:text-white active:text-white font-normal"
              to={"/"}
            >
              <img
                alt="Urban Truant Rib Club Logo"
                width={180}
                height={30}
                className="h-auto w-full md:h-[30px] md:w-auto"
                src="https://swt-assets.s3.eu-west-2.amazonaws.com/Urban-Truant-Logo.png"
              />
            </Link>
          </div>
          <div className="px-5 grow">
            <div
              className={`${menuOpen ? "left-0" : "left-full"} 
              fixed top-0 w-full h-full bg-white z-50 p-10 transition-all
              md:static md:bg-transparent md:p-0
              `}
            >
              <div className="md:hidden">
                <span
                  className="absolute top-6 right-6 text-dark"
                  onClick={() => setMenuOpen(false)}
                >
                  <Icon size={1} path={mdiClose} />
                </span>
                <h2 className="text-primary font-2xl font-bold border-b-2 max-w-[70px] border-grey mb-5">
                  MENU
                </h2>
              </div>
              <nav className="flex flex-col md:flex-row md:justify-end md:-mx-3">
                {HEADER_MENU.items.map((menuItem) => {
                  return (
                    <Link
                      key={menuItem.slug}
                      className="
                        text-3xl text-dark active:text-primary font-bold py-3 
                        md:text-sm md:text-white md:px-3 md:py-0 md:font-normal md:cursor-pointer header-menu-item"
                      to={menuItem.slug}
                    >
                      {menuItem.name}
                    </Link>
                  );
                })}
                <div
                  className="
                        md:hidden
                        text-3xl text-dark active:text-primary font-bold py-3 
                        md:text-sm md:text-white md:px-3 md:py-0 md:font-normal md:cursor-pointer header-menu-item"
                >
                  <Button
                    className="bg-primary border-primary text-dark text-lg"
                    onClick={gotToMembersArea}
                  >
                    Members Area
                  </Button>
                </div>
              </nav>
            </div>
          </div>
          <div className="px-5 md:hidden">
            <div className="flex -mx-2">
              <div className="flex px-2">
                <button onClick={() => setMenuOpen(true)}>
                  <Icon size={1} path={mdiMenu} />
                </button>
              </div>
            </div>
          </div>
          <div className="px-5 hidden md:block">
            <Button
              className="bg-primary border-primary text-dark text-sm px-2 py-1"
              onClick={gotToMembersArea}
            >
              Members Area
            </Button>
          </div>
        </div>
      </Container>
    </header>
  );
}
