import React from "react";
import { StructuredText } from "react-datocms";
import { string } from "yup";
import Container from "../../../components/shared/Container";
import SiteForm from "../../../components/shared/Form";
import WaterTaxiApi from "../../../services/WaterTaxiApi";
import { ContactResponseInput } from "../../../services/WaterTaxiApi/ContactResponseService/types";
import { DatoCmsContentBase } from "../../types";
import { HUT_FORM } from "./config";

import Modal from "@mui/material/Modal";
import Icon from "@mdi/react";
import { mdiCheckAll } from "@mdi/js";
import Button from "../../../components/shared/Button";

interface Props {
  section: DatoCmsContentBase;
}

export default function HutForm({ section }: Props) {
  const [submitting, setSubmitting] = React.useState(false);
  const [data, setData] = React.useState<Record<string, any>>({});

  const [modalContent, setModalContent] = React.useState<null | {
    title: string;
    content: string;
  }>(null);

  const handleSubmit = async (data: any) => {
    // map to custom object

    setSubmitting(true);

    const ref = `${document.title}`;

    const input: ContactResponseInput = {
      ref,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      message: data.message,
      other: "{}",
    };

    // create other remove fields form input

    input.other = JSON.stringify({
      ...data,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      message: undefined,
    });

    const success = await WaterTaxiApi.ContactResponseService.submit(input);

    if (success) {
      setData({});
      setModalContent({
        title: "Thank you for your enquiry",
        content: "We will be in touch shortly",
      });
    }

    setSubmitting(false);
  };

  return (
    <Container>
      <div>
        <h2 className="text-2xl font-bold mb-4">{section.title}</h2>
        {section.content != undefined && (
          <StructuredText data={section.content} />
        )}
      </div>

      <SiteForm
        data={data}
        setData={setData}
        configuration={HUT_FORM}
        onSubmit={handleSubmit}
        loading={submitting}
        setLoading={setSubmitting}
      />

      <Modal open={modalContent != null} onClose={() => setModalContent(null)}>
        <div className="flex justify-center items-center h-full">
          <div className="p-4 bg-white rounded shadow max-w-screen-lg text-center">
            <div className="flex justify-center">
              <Icon path={mdiCheckAll} size={2} />
            </div>
            <h2 className="text-3xl font-bold mb-2">{modalContent?.title}</h2>
            <p>{modalContent?.content}</p>
            <div className="mt-3 mb-1">
              <Button onClick={() => setModalContent(null)}>Okay</Button>
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
}
